<template>
  <div>
     <span
         class="border border-dark blue darken-2 rounded-lg py-2 px-3 white--text text-body-1"
         v-if="isTaskOnTime(task.status,task.completedDate,task.task_endDate)">
       A TIEMPO
     </span>
    <span class="border border-dark red darken-2 rounded-lg py-2 px-3 white--text text-body-1"
          v-else>
      CON RETRASO
    </span>
  </div>

</template>

<script>


import {isTaskOnTime} from "./common";

export default {
  name: "taskStatus",

  props: ['task'],

  data: function() {
    return {
    }
  },

  watch: {},

  components: {},

  methods: {
    isTaskOnTime
  }
}
</script>

<style scoped>
</style>
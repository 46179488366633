import moment from "moment/moment";


export const STATUS_STYLES = {
    COMPLETED: "rgb(51, 182, 121)",
        PENDING: "#777272",
        DELAYED: "red",
        OVERDUE: "orange"
}

export const STATUS_TYPES = [
    { text: "Finalizada", value: "Finalizada" },
    { text: "Pendiente", value: "Pendiente" }
];

export const TASK_TYPES = [
    { text: "Puntual", value: "Puntual" },
    { text: "Recurrente", value: "Recurrente" }
];

export const TEMPLATE_TYPES = [
    { text: "Inicio Proyecto", value: "PROJECT_START" },
    { text: "Aumento GPV's", value: "GPVINCREASE" },
    { text: "Nuevo Canal", value: "NEWCHANNEL" },
    { text: "Otros", value: "OTHERS" }
];

export const PRIORITY_TYPES = [
    { text: "Alta", value: "Alta" },
    { text: "Media", value: "Media" },
    { text: "Baja", value: "Baja" }
];

export const FREQ_ITEMS = [
    { text: "días", value: "days" },
    { text: "semanas", value: "weeks" },
    { text: "meses", value: "months" },
    { text: "años", value: "years" }
]

export const WORKINGDAYS_ITEMS = [
    { text: "Domingo", value: "Sun" },
    { text: "Lunes", value: "Mon" },
    { text: "Martes", value: "Tue" },
    { text: "Miercoles", value: "Wed" },
    { text: "Jueves", value: "Thu" },
    { text: "Viernes", value: "Fri" },
    { text: "Sabado", value: "Sat" }
]


export const  isTaskOnTime = (status, completedDate, endDate) => {
    let today = moment(new Date())
        .local()
        .format("YYYY-MM-DD");
    if (status === "Finalizada") return completedDate <= endDate;
    return today <= endDate;
};


export const getStyleFromStatus = (status, completedDate, endDate) => {
    let styleType = null;
    if (isTaskOnTime(status, completedDate, endDate)) {
        if (status === "Finalizada") styleType = STATUS_STYLES.COMPLETED;
        else styleType = STATUS_STYLES.PENDING;
    } else {
        if (status === "Finalizada") styleType = STATUS_STYLES.OVERDUE;
        else styleType = STATUS_STYLES.DELAYED;
    }
    return `background-color: ${styleType}`;
};